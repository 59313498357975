import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import "./UnitPdf.css";

export default function UnitPdf({ onDataLoaded }) {
  const [unitData, setUnitData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchUnitData = async () => {
      try {
        const response = await axios.post(
          "https://dash.ogeedecor.com/home/unit-price/",
          { unit_id: id },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        );
        setUnitData(response.data);
        setLoading(false);

        // Inform parent that the data is loaded
        if (onDataLoaded) {
          onDataLoaded();
        }
      } catch (err) {
        console.error("Error fetching unit data:", err); // Log the full error
        if (err.response) {
          console.log("Server responded with a status code outside the 2xx range");
          console.log("Response status:", err.response.status);
          console.log("Response headers:", err.response.headers);
          console.log("Response data:", err.response.data);
        } else if (err.request) {
          console.log("No response was received from the server");
          console.log("Request details:", err.request);
        } else {
          console.log("Something went wrong while setting up the request", err.message);
        }
        setError(err);
        setLoading(false);
      }
    };

    fetchUnitData();
  }, [id, onDataLoaded]);

  const convertImageToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // This is necessary for cross-origin images
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/jpeg");
        resolve(dataURL);
      };
      img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
    });
  };

  const generatePdf = async () => {
    const element = document.getElementById("pdf-content");

    // Find all images in the content
    const images = element.querySelectorAll("img");

    // Convert each image to Base64 and replace its src
    for (let img of images) {
      try {
        const base64Image = await convertImageToBase64(img.src);
        img.src = base64Image;
      } catch (error) {
        console.error("Error converting image to Base64:", error);
      }
    }

    // Generate the PDF and return as a Blob
    const options = {
      margin: 0,
      filename: `Unit_${id}_Details.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: "in", format: [15, 50], orientation: "portrait" },
    };

    return new Promise((resolve, reject) => {
      html2pdf()
        .set(options)
        .from(element)
        .outputPdf("blob")
        .then((pdfBlob) => {
          resolve(pdfBlob);
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          reject(error);
        });
    });
  };

  const uploadPdf = async (pdfBlob) => {
    const formData = new FormData();
    formData.append("file", pdfBlob, `Unit_${id}_Details.pdf`);

    try {
      const response = await axios.post(`https://dash.ogeedecor.com/home/upload-pdf/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("PDF uploaded successfully", response.data);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const downloadPdf = (pdfBlob) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `Unit_${id}_Details.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGenerateAndUploadPdf = async () => {
    try {
      const pdfBlob = await generatePdf();
      await uploadPdf(pdfBlob);
      downloadPdf(pdfBlob); // Trigger the download
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !unitData) {
    return <div>Error loading data.</div>;
  }

  return (
    <>
      <div id="pdf-content" class="Pdf_Container">
        <header class="Pdf_Header">
          <div class="Header_P1">
            <div>
              {/* <img src="./Logo.png" alt="" class="Logo" /> */}
              <h1 class="Header_H1">Home Name</h1>
            </div>
            <div>
              <h2 class="Header_H2">Units budgeting details</h2>
              <h2 class="Header_H2">
                Created by <strong>Ahmed Raafat</strong>
              </h2>
            </div>
          </div>
          <div class="Header_P2">
            <div class="Header_P2_S">
              <h2 class="Header_H2">total Apartment finishing budget</h2>
              <h2 class="Header_H2 Price">{unitData.Total_Unit_Price.toLocaleString()} EGP</h2>
            </div>
            <div class="Header_P2_S">
              <h2 class="Header_H2">total Apartment Area</h2>
              <h2 class="Header_H2">
                {unitData.rooms.reduce((total, room) => total + room.Floor_Area, 0)} <strong class="Price">m2</strong>
              </h2>
            </div>
          </div>
        </header>
        <article>
          <table class="Table">
            {/* this is Thead for Architect , Budget */}
            <thead>
              <tr class="Table_T1">
                <th class="Table_T1_B">Architect</th>
                <th class="Table_T1_B">Budget</th>
                <th class="Table_T1_B">Units</th>
                <th class="Table_T1_B">Description of item</th>
                <th class="Table_T1_B">Design</th>
                <th>Description of Design</th>
              </tr>
            </thead>
            {/* Rooms Data */}
            <tbody>
              {unitData.rooms.map((room) => (
                <React.Fragment key={room.Room_ID}>
                  <tr class="Spacer_Row"></tr>
                  {/* next one for room name with the id of room exmaple : - room name : Reception - room id : 3740 */}
                  <tr class="Table_T3">
                    <td colspan="1" class="Table_Td">
                      {room.Room_Name} {room.Room_ID}
                    </td>
                  </tr>
                  {/* Reception First Line include Image */}
                  <tr>
                    <td>Walls painting</td>
                    <td class="Td_Price">{room.Wall_Material_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Wall_Material_Name}</td>
                    {/* here will be Img for Design */}
                    <td rowspan="7" class="Td_Img_Main">
                      <img
                        src={`https://dash.ogeedecor.com${room.Images?.[0]}`}
                        alt={`${room.Room_Name} Design`}
                        className="Td_Img"
                        onError={(e) => {
                          console.error("Error loading image", e);
                        }}
                      />
                    </td>
                    <td rowspan="7" class="Td_Last">
                      <p> {room.Description_Of_Design_Arabic || room.Description_Of_Design_English || "No description provided "}</p>
                    </td>
                  </tr>
                  {/* Reception Walls decorations -1 */}
                  <tr>
                    <td>Walls decorations -1</td>
                    <td class="Td_Price">{room.Wall_Material_Addon1_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Wall_Material_Addon1_Name}</td>
                  </tr>
                  {/* Reception Walls decorations -2 */}
                  <tr>
                    <td>Walls decorations -2</td>
                    <td class="Td_Price">{room.Wall_Material_Addon2_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Wall_Material_Addon2_Name}</td>
                  </tr>
                  {/* Reception Ceiling Painting */}
                  <tr>
                    <td>Ceiling Painting</td>
                    <td class="Td_Price">{room.Ceiling_Material_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Ceiling_Material_Name}</td>
                  </tr>
                  {/* Reception Ceiling decoration - 1 */}
                  <tr>
                    <td>Ceiling decoration - 1</td>
                    <td class="Td_Price">{room.Ceiling_Material_Addon1_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Ceiling_Material_Addon1_Name}</td>
                  </tr>
                  {/* Reception Ceiling decoration - 2 */}
                  <tr>
                    <td>Ceiling decoration - 2</td>
                    <td class="Td_Price">{room.Ceiling_Material_Addon1_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Ceiling_Material_Addon2_Name}</td>
                  </tr>
                  <tr>
                    <td>Flooring</td>
                    <td class="Td_Price">{room.Floor_Material_Price.toFixed(1)}</td>
                    <td class="Td_Currency">EGP</td>
                    <td>{room.Floor_Material_Name}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
            {/*  */}

            {/*  */}
            {/* Spaces */}
            <tr class="Spacer_Row"></tr>
            <tr class="Spacer_Row"></tr>
            {/* Total of architectural works */}
            <thead>
              <tr class="Table_T2">
                <th class="Table_T1_N">Total of architectural works</th>
                <th class="Table_T1_N">{unitData.Total_Architectural_Works.toFixed(1)}</th>
                <th class="Table_T1_N">EGP</th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_F"></th>
              </tr>
            </thead>
            <tr class="Spacer_Row"></tr>
            <tr class="Spacer_Row"></tr>
            {/* this to display Svg Plus we made it as thead th to continue in same design */}
            <thead>
              <tr class="Table_Svg">
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <rect y="13" width="32" height="6" fill="#777A70" />
                    <rect x="19" width="32" height="6" transform="rotate(90 19 0)" fill="#777A70" />
                  </svg>
                </th>
              </tr>
            </thead>
            {/* this is Thead for Openings , Budget , Units , ..... */}
            {/* <tr class="Page_Break"></tr> */}

            <thead>
              <tr class="Spacer_Row"></tr>
              <tr class="Table_T1">
                <th class="Table_T1_B">Openings</th>
                <th class="Table_T1_B">Budget</th>
                <th class="Table_T1_B">Units</th>
                <th class="Table_T1_B">Quantity</th>
                <th class="Table_T1_B">Type</th>
                <th class="Table_T1_B"></th>
              </tr>
            </thead>
            {/* for window */}
            <tbody>
              <tr class="Spacer_Row"></tr>
              {/* next one for windows and/balcony Area for unit */}
              <tr class="Table_T3">
                <td colspan="1" class="Table_Td">
                  windows and/balcony Area
                </td>
              </tr>
              {/* windows and/balcony Windows Budget */}
              <tr class="Table2_Tr">
                <td>Windows Budget</td>
                <td class="Td_Price">{unitData.Window_Budget.toFixed(1)}</td>
                <td class="Td_Currency">EGP</td>
                <td>{unitData.window_quantity.toLocaleString()}</td>
                {/* here will be Img for Design */}
                <td rowspan="1" class="">
                  no.
                </td>
                <td rowspan="1" class="Td_Last">
                  <p></p>
                </td>
              </tr>
            </tbody>
            {/* for doors */}
            <tbody>
              <tr class="Spacer_Row"></tr>
              {/* next one for Doors Area for unit */}
              <tr class="Table_T3">
                <td colspan="1" class="Table_Td">
                  Doors
                </td>
              </tr>
              {/* Doors Budget */}
              <tr class="Table2_Tr">
                <td>Doors Budget</td>
                <td class="Td_Price">{unitData.Door_Budget.toFixed(1)}</td>
                <td class="Td_Currency">EGP</td>
                <td>{unitData.door_quantity.toLocaleString()}</td>
                {/* here will be Img for Design */}
                <td rowspan="1" class="">
                  no.
                </td>
                <td rowspan="1" class="Td_Last">
                  <p></p>
                </td>
              </tr>
            </tbody>
            {/*  */}
            <tr class="Spacer_Row"></tr>
            {/* next thead for display Total of Openings works */}
            <thead>
              <tr class="Table_T2">
                <th class="Table_T1_N">Total of Openings works</th>
                <th class="Table_T1_N">{unitData.Total_Of_Opening_Works.toFixed(1)}</th>
                <th class="Table_T1_N">EGP</th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_F"></th>
              </tr>
            </thead>
            {/* Speaces */}
            <tr class="Spacer_Row"></tr>
            <tr class="Spacer_Row"></tr>
            {/* page pdf break down */}
            <tr class="Spacer_Row"></tr>
            <tr class="Spacer_Row"></tr>
            {/* next thead for display MEP-Electrical & plumping */}
            <thead>
              <tr class="Table_T1">
                <th class="Table_T1_B">MEP-Electrical & plumping</th>
                <th class="Table_T1_B">Budget</th>
                <th class="Table_T1_B">Units</th>
                <th class="Table_T1_B"></th>
                <th class="Table_T1_B"></th>
                <th class="Table_T1_B"></th>
              </tr>
            </thead>
            {/* next tbody for display  Electrical - Phase 1 */}
            <tbody>
              <tr class="Spacer_Row"></tr>
              {/* next one for Electrical - Phase 1 for unit */}
              <tr class="Table_T3">
                <td class="Table_Td">Electrical</td>
              </tr>
              {/* next for Electrical - Finish */}
              <tr class="Table2_Tr">
                {/* Electrical - Phase 1 */}
                <td>Electrical - Phase 1</td>
                {/* Budget */}
                <td class="Td_Price">{unitData.Electrical_Phase_1.toFixed(1)}</td>
                {/* Units */}
                <td class="Td_Currency">EGP</td>
                {/* Quantity / Space */}
                <td></td>
                {/* Type */}
                <td class=""></td>
                <td class="Td_Last">
                  <p></p>
                </td>
              </tr>
              <tr class="Table2_Tr">
                {/* Electrical - Phase 1 */}
                <td>Electrical - Finish</td>
                {/* Budget */}
                <td class="Td_Price">{unitData.Electrical_Finish.toFixed(1)}</td>
                {/* Units */}
                <td class="Td_Currency">EGP</td>
                {/* Quantity / Space */}
                <td></td>
                {/* Type */}
                <td class=""></td>
                <td class="Td_Last">
                  <p></p>
                </td>
              </tr>
            </tbody>
            {/*  */}
            {/*  */}
            {/*  */}
            {/* next tbody for display Plumping - Phase 1 */}
            <tbody>
              <tr class="Spacer_Row"></tr>
              {/* next one for Plumping - Phase 1 for unit */}
              <tr class="Table_T3">
                <td class="Table_Td">Plumping </td>
              </tr>
              {/* next for Plumping - Phase 1 */}
              <tr class="Table2_Tr">
                {/* Plumping - Phase 1 */}
                <td>Plumping - Phase 1</td>
                {/* Budget */}
                <td class="Td_Price">{unitData.Plumbing_Phase_1.toFixed(1)}</td>
                {/* Units */}
                <td class="Td_Currency">EGP</td>
                {/* Quantity / Space */}
                <td></td>
                {/* Type */}
                <td class=""></td>
                <td class="Td_Last">
                  <p></p>
                </td>
              </tr>
              <tr class="Table2_Tr">
                {/* Plumping - Phase 1 */}
                <td>Plumping - Finish</td>
                {/* Budget */}
                <td class="Td_Price">{unitData.Plumbing_Finish.toFixed(1)}</td>
                {/* Units */}
                <td class="Td_Currency">EGP</td>
                {/* Quantity / Space */}
                <td></td>
                {/* Type */}
                <td class=""></td>
                <td class="Td_Last">
                  <p></p>
                </td>
              </tr>
            </tbody>
            {/* Accessories */}
            <tbody>
              <tr class="Spacer_Row"></tr>
              {/* next one for Accessories for unit */}
              <tr class="Table_T3">
                <td class="Table_Td">Accessories</td>
              </tr>
              {/* next for Airconditionings */}
              <tr class="Table2_Tr">
                {/* Accessories */}
                <td>Airconditionings</td>
                {/* Budget */}
                <td class="Td_Price">{unitData.Airconditioning.toFixed(1)}</td>
                {/* Units */}
                <td class="Td_Currency">EGP</td>
                {/* Quantity / Space */}
                <td></td>
                {/* Type */}
                <td></td>
                <td class="Td_Last"></td>
              </tr>
            </tbody>
            <tr class="Spacer_Row"></tr>
            <tr class="Spacer_Row"></tr>
            {/* next for Total of MEP works */}
            <thead>
              <tr class="Table_T2">
                <th class="Table_T1_N">Total of MEP works</th>
                <th class="Table_T1_N">{unitData.Total_MEP_Work.toFixed(1)}</th>
                <th class="Table_T1_N">EGP</th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_F"></th>
              </tr>
            </thead>
            <tr class="Spacer_Row"></tr>
            {/* this to display Svg Plus we made it as thead th to continue in same design */}
            <thead>
              <tr class="Table_Svg">
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <rect y="13" width="32" height="6" fill="#777A70" />
                    <rect x="19" width="32" height="6" transform="rotate(90 19 0)" fill="#777A70" />
                  </svg>
                </th>
              </tr>
            </thead>
            {/* end Svg Plus */}
            <tr class="Spacer_Row"></tr>
            {/* next for display Addetional works */}
            <thead>
              <tr class="Table_T2">
                <th class="Table_T1_N">Addetional works تشوينات</th>
                <th class="Table_T1_N">{unitData.Additional_Works.toFixed(1)}</th>
                <th class="Table_T1_N">EGP</th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_C"></th>
                <th class="Table_T1_F"></th>
              </tr>
            </thead>
            {/*  */}
            <tr class="Spacer_Row"></tr>
            <tr class="Spacer_Row"></tr>
          </table>
          {/* this to display Svg Equal */}
          <div class="Svg_Equal">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20" fill="none">
              <rect width="32" height="6" fill="#777A70" />
              <rect x="32" y="20" width="32" height="6" transform="rotate(-180 32 20)" fill="#777A70" />
            </svg>
          </div>
          {/* end Svg Equal */}
          {/* next table for display total amout of finishing budget */}
          <table class="Table_LAST">
            <thead>
              <tr class="Spacer_Row"></tr>
              <tr class="Table_T3">
                <th class="Foot_Th1">total Apartment finishing budget</th>
                <th class="Foot_Th2">{unitData.Total_Unit_Price.toLocaleString()}</th>
                <th class="Foot_Th3">EGP</th>
                <th class="Foot_Th4">total Apartment Area</th>
                <th class="Foot_Th5">{unitData.rooms.reduce((total, room) => total + room.Floor_Area, 0)}</th>
                <th class="Foot_Th6">m2</th>
              </tr>
            </thead>
          </table>
        </article>

        <button onClick={handleGenerateAndUploadPdf}>Download as PDF</button>
      </div>
    </>
  );
}
