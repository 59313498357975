import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import UnitPdf from "./UnitPdf"; // Import the UnitPdf component

const CreatePdf = () => {
  const { id } = useParams();
  const pdfContentRef = useRef();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleDataLoaded = () => {
    setIsDataLoaded(true); // Set to true when UnitPdf has finished loading the data
  };

  useEffect(() => {
    const generatePdf = async () => {
      console.log("Generating PDF...");

      try {
        // Give it a slight delay to ensure the content is fully rendered
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Convert UnitPdf component to canvas
        const canvas = await html2canvas(pdfContentRef.current, {
          scale: 3, // Higher scale to increase resolution
          useCORS: true, // Ensure cross-origin images are allowed
          allowTaint: false, // Prevent tainted canvases due to cross-origin images
        });

        const imgData = canvas.toDataURL("image/jpeg", 0.5); // Compress the image

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 190; // Width for the image in the PDF
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Image height based on aspect ratio
        let heightLeft = imgHeight;

        let position = 15; // Starting position for the image in the PDF

        // Add the image to the PDF, splitting it into multiple pages if needed
        while (heightLeft > 0) {
          pdf.addImage(imgData, "JPEG", 15, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          position = heightLeft > 0 ? position - pageHeight : 0; // Set position for the next page

          // Add a new page if there is content left
          if (heightLeft > 0) {
            pdf.addPage();
          }
        }

        // Save the PDF as a Blob
        const pdfBlob = pdf.output("blob");

        // Check PDF size (should be less than 5 MB)
        const pdfSizeMB = (pdfBlob.size / (1024 * 1024)).toFixed(2); // Size in MB
        console.log(`PDF size: ${pdfSizeMB} MB`);

        if (pdfBlob.size > 5 * 1024 * 1024) {
          console.warn("PDF size exceeds 5 MB!");
        }

        console.log("PDF generated, starting upload...");

        // Upload the PDF
        const formData = new FormData();
        formData.append("file", pdfBlob, "rooms.pdf");

        const uploadResponse = await axios.post(`https://dash.ogeedecor.com/home/upload-pdf/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Upload completed.");

        // Automatically download the PDF
        const pdfUrl = `https://dash.ogeedecor.com${uploadResponse.data.file_path}`;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "rooms.pdf";
        link.click();
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    };

    // Generate the PDF only when the data is fully loaded
    if (isDataLoaded) {
      console.log("Data is loaded, triggering PDF generation...");
      generatePdf();
    }
  }, [id, isDataLoaded]);

  return (
    <div>
      <div ref={pdfContentRef}>
        {/* Pass the handleDataLoaded callback to UnitPdf */}
        <UnitPdf unitId={id} onDataLoaded={handleDataLoaded} />
      </div>
      {isDataLoaded ? "Generating PDF..." : "Loading data..."}
    </div>
  );
};

export default CreatePdf;
