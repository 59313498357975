import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./GoEditRoom.css";
import { useAuth } from "../../Assets/components/Auth/AuthContext";
import axios from "axios";
import Select from "react-select";
import { MainUrl } from "../../config";
import UserLogged from "../../Assets/components/Menu/userLogged/userLogged";
export default function GoEditRoomContent() {
  const [selectedDesignCode, setSelectedDesignCode] = useState("");
  const history = useNavigate();
  const [rooms, setRooms] = useState([]);
  const { token } = useAuth();
  const [lastFetchedData, setLastFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = (e) => {
    setSelectedDesignCode(e.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedDesignCode(selectedOption ? selectedOption.value : "");
  };

  const handleGoEditRoom = () => {
    if (selectedDesignCode.trim()) {
      const selectedRoom = rooms.find(
        (room) => room.design_code === selectedDesignCode
      );
      if (selectedRoom) {
        history(`/Room/${selectedRoom.id}/edit`);
      } else {
        alert("Selected design code does not match any room ID");
      }
    } else {
      alert("Please enter or select a valid Design Code");
    }
  };
  const initialFetchRooms = async () => {
    setIsLoading(true); // Start loading
    await fetchRooms();
    setIsLoading(false); // End loading after initial data is fetched
  };
  const fetchRooms = async () => {
    try {
      if (!token) {
        history("/login");
        console.log("No token found");
        return;
      }

      const response = await axios.get(`${MainUrl}home/rooms/gallery-rooms/`, {
        headers: {
          accept: "application/json",
          Authorization: `Token ${token}`,
        },
      });

      // Extracting the rooms from the paginated response
      const { results: fetchedRooms } = response.data;

      // Sort rooms if necessary
      const sortedRooms = fetchedRooms.sort((a, b) => b.id - a.id);

      // Check if fetched data is different from the last fetched data
      if (JSON.stringify(sortedRooms) !== JSON.stringify(lastFetchedData)) {
        setRooms(sortedRooms);
        setLastFetchedData(sortedRooms);
      }
    } catch (error) {
      console.error("Error fetching rooms data:", error);
    }
  };

  useEffect(() => {
    initialFetchRooms();
  }, []);

  const designCodeOptions = rooms.map((room) => ({
    value: room.design_code,
    label: room.design_code,
  }));

  const LoadingPopup = () => (
    <div className="loading-popup">
      <div className="loading-circle">
        <div className="loading-semicircle"></div>
      </div>
      <div className="loading-text">
        Loading<span className="loading-dots"></span>
      </div>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <LoadingPopup />
      ) : (
        <>
          <div>
            <div className="GoEdit_UserLogged">
              <UserLogged />
            </div>
            <div className="GoEdit_Container">
              <div className="GoEdit_Main">
                <div>
                  Welcome! Please enter the design code for the room you want to
                  edit
                </div>
                <div className="input-container">
                  <div className="GoEdit_Box">
                    <h3>Choose Design code</h3>
                    <Select
                      className="GoEdit_Select_Box"
                      value={designCodeOptions.find(
                        (option) => option.value === selectedDesignCode
                      )}
                      onChange={handleSelectChange}
                      options={designCodeOptions}
                      placeholder="Select a design code..."
                      isClearable
                    />
                  </div>
                  <div>or</div>
                  <div className="GoEdit_Box">
                    <h3>Enter Design code</h3>
                    <input
                      type="text"
                      className="styled-input"
                      placeholder="Design code"
                      value={selectedDesignCode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="GoEdit_Room_Button"
                    onClick={handleGoEditRoom}
                  >
                    Go Edit Room
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
