import React from "react";
import HomePageContent from "./HomePage";

function HomePage() {
  return (
    <>
      <HomePageContent />
    </>
  );
}

export default HomePage;
