import React from "react";
import AddRoomsDetailsContent from "./AddRoomsDetails";

function AddRoomsDetails() {
  return (
    <>
      <AddRoomsDetailsContent />
    </>
  );
}

export default AddRoomsDetails;
