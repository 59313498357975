import React from "react";
import LoginContent from "./Login";
function Login() {
  return (
    <>
      <LoginContent />
    </>
  );
}

export default Login;
