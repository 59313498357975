import React from "react";
import ExportDecorationsContent from "./ExportDecorations";

function ExportDecorations() {
  return (
    <>
      <ExportDecorationsContent />
    </>
  );
}

export default ExportDecorations;
