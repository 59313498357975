import React from "react";
import AddRoom from "./AddRooms";

function AddRoomF() {
  return (
    <>
      <AddRoom />
    </>
  );
}

export default AddRoomF;
