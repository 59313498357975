import React from "react";
import ImportDecorationsContent from "./ImportDecorations";

function ImportDecorations() {
  return (
    <>
      <ImportDecorationsContent />
    </>
  );
}

export default ImportDecorations;
