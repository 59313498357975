import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div>
        <footer class="footer">
          <p>&copy;2024 Ogee Decor | All Rights Reserved</p>
        </footer>
      </div>
    </>
  );
}
