import React from "react";
import ChangePasswordContent from "./Change_Password";

function ManageUser() {
  return (
    <>
      <ChangePasswordContent />
    </>
  );
}

export default ManageUser;
