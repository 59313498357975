import React from "react";
import RoomsContent from "./Rooms";

function RoomsPage() {
  return (
    <>
      <RoomsContent />
    </>
  );
}

export default RoomsPage;
