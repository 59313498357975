import React from "react";
import ManageUserContent from "./Manage_User";

function ManageUser() {
  return (
    <>
      <ManageUserContent />
    </>
  );
}

export default ManageUser;
