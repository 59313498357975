import React from "react";
import GoEditRoomContent from "./GoEditRoom";

function GoEditRoom() {
  return (
    <>
      <GoEditRoomContent />
    </>
  );
}

export default GoEditRoom;
